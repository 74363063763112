import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Row, Col } from 'react-bootstrap';

const SoloGuidesArtSets: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Artifact Stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_arti2.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Artifact Stats</h1>
          <h2>
            List of all Artifact Stats you can roll on your Artifacts in Solo
            Leveling: Arise.
          </h2>
          <p>
            Last updated: <strong>28/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Artifact Stats" />
        <p>
          <strong>Artifacts</strong> are items in Solo Leveling: Arise that can
          be equipped on Hunters to increase their stats and if you{' '}
          <strong>
            combine the available Sets, you will unlock additional traits
          </strong>
          . There are 8 slots available on the Artifact and each slot has
          different Main Stats and Sub Stats options available. Below you can
          find a list of all available slots and stats you can roll on your
          items - yes, roll. While some pieces have only one Main Stat
          available, others have multiple, so prepare for a lot of RNG when you
          will hunt for the perfect Artifact for your characters!
        </p>
        <p>
          <strong>
            Please keep in mind that Sub Stats can't be the same as the Main
            Stat
          </strong>
          . So for example if you roll Attack (%) as your Main Stat, you won't
          be able to obtain it as a Sub Stat.
        </p>
        <SectionHeader title="Main Stats & Sub Stats" />
        <h5>Color legend</h5>
        <ul>
          <li>
            <strong className="offensive">Offensive</strong>
          </li>
          <li>
            <strong className="defensive">Defensive</strong>
          </li>
          <li>
            <strong className="utility">Utility</strong>
          </li>
        </ul>
        <Row xs={1} xxl={2} className="solo-row">
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Helmet.png"
                  alt="Artifact Helmet"
                />
                <span>Helmet</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">Additional MP</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Rate</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Body.png"
                  alt="Artifact Body"
                />
                <span>Body</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="defensive">Additional Defense</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">Additional MP</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Rate</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Gloves.png"
                  alt="Artifact Gloves"
                />
                <span>Gloves</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="offensive">Additional Attack</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">Additional MP</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Rate</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Shoes.png"
                  alt="Artifact Shoes"
                />
                <span>Boots</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="offensive">Critical Hit Damage</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">Additional MP</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Rate</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Necklace.png"
                  alt="Artifact Necklace"
                />
                <span>Necklace</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="defensive">Additional HP</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">Additional MP</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Damage</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Bracelet.png"
                  alt="Artifact Bracelet"
                />
                <span>Bracelet</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="offensive">Dark Damage (%)</li>
                  <li className="offensive">Fire Damage (%)</li>
                  <li className="offensive">Light Damage (%)</li>
                  <li className="offensive">Water Damage (%)</li>
                  <li className="offensive">Wind Damage (%)</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">Additional MP</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Damage</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Earing.png"
                  alt="Artifact Bracelet"
                />
                <span>Earrings</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="utility">Additional MP</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Damage</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box solo-artifact">
              <div className="solo-artifact-header">
                <StaticImage
                  src="../../../images/solo/icons/Artifact_Ring.png"
                  alt="Artifact Bracelet"
                />
                <span>Ring</span>
              </div>
              <div className="solo-artifact-content">
                <h5>Main Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                </ul>
                <h5 className="with-margin">Sub Stats</h5>
                <ul>
                  <li className="offensive">Attack (%)</li>
                  <li className="offensive">Additional Attack</li>
                  <li className="defensive">HP (%)</li>
                  <li className="defensive">Additional HP</li>
                  <li className="defensive">Defense (%)</li>
                  <li className="defensive">Additional Defense</li>
                  <li className="offensive">Defense Penetration</li>
                  <li className="offensive">Damage Increase</li>
                  <li className="defensive">Damage Reduction</li>
                  <li className="utility">Healing Given Increase (%)</li>
                  <li className="utility">Additional MP</li>
                  <li className="utility">MP Consumption Reduction</li>
                  <li className="offensive">Critical Hit Damage</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesArtSets;

export const Head: React.FC = () => (
  <Seo
    title="Artifact Stats | Solo Leveling: Arise | Prydwen Institute"
    description="List of all Artifact Stats you can roll on your Artifacts in Solo Leveling: Arise."
    game="solo"
  />
);
